import { useSelector } from 'react-redux'
import { authenticate } from './slices/authSlice'
import {Button, Card, Form, Input, Layout, Row, Spin, theme, message} from 'antd';
import {hashCode} from "./utils";
import store from './store'
import {connectToMongoDB, loginEmailPassword} from "./realm";
import {useState} from "react";

const {Content} = Layout
// import './Login.css';




function Login() {
    const [messageApi, contextHolder] = message.useMessage();
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [isLoading, setLoading] = useState(false);
    const onFinish = (values) => {
        //add spin state
        setLoading(true)
        const user = loginEmailPassword(values.username, values.password)
            .then((res) => {
                connectToMongoDB()
                store.dispatch(authenticate(true));
            })
            .catch((e)=>{
                // error message
                messageApi.open({
                    type: 'error',
                    content: '登录错误！请重新登录！',
                });
                throw e
            })
            .finally(()=>{
                //remove spin state
                setLoading(false)
            })

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            {contextHolder}
            <Content
                style={{
                }}
            >
                <div
                    className="site-layout-content"
                    style={{
                        background: colorBgContainer,
                    }}
                >
                    <Row justify="center" align="middle" style={{height: '100vh'}}>
                        <Card title="燕子河大峡谷数藏门票核销系统" style={{ width: 400 }}>
                            {isLoading && <Spin />}
                            <Form
                                    name="basic"
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    style={{ maxWidth: 600 }}
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                <Form.Item
                                    label="用户名"
                                    name="username"
                                    rules={[{ required: true, message: '请输入用户名!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="密码"
                                    name="password"
                                    rules={[{ required: true, message: '请输入密码!' }]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit" disabled={isLoading}>
                                        登录
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Row>
                </div>
            </Content>
        </>
    );
}
export default Login;
