import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import thunk from 'redux-thunk';


const persistConfig = {
    key: 'NFRRoot',
    storage: storageSession,
}

const persistedReducer = persistReducer(persistConfig, authReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})

export const persistor = persistStore(store)

export default store
