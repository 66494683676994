import {Alert, Button, Form, Input, Layout, Menu, Modal, Select, Space, Spin, theme, message, Result} from 'antd';
import { useState } from 'react';
import realmApp, {collection} from "./realm";
import store from "./store";
import {authenticate} from "./slices/authSlice";
const { Header, Content, Sider } = Layout;
const { Option } = Select;
const _ = require('lodash');

function getItem(label, key, value) {
    return {
        key,
        value,
        label,
    };
}
const items = [
    getItem('活动一', '1' ,1),
    getItem('活动二', '2',2),
    getItem('活动三', '3' ,3),
    getItem('活动四', '4',4),
];

const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

function Dashboard() {
    const [collapsed, setCollapsed] = useState(false);
    const [activityCat, setActivityCat] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [verifiedNFR, setVerifiedNFR] = useState([]);
    const [isError, setIsError] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const [form] = Form.useForm();

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onMenuClick = (e) => {
        setActivityCat(parseInt(e.key))
    }


    const insertNewNFROrder = (values) => {
        let NFRList={"Activity": activityCat, NFR:[], Date: new Date(Date.now()).toISOString(), Contact_number: values.phone_number}
        //console.log(values);
        for (let i = 1; i <= activityCat; i++) {
            NFRList.NFR.push({"type": values[i+"_nfr"], "number":values[i+"_number"]})
        }
        collection.insertOne(NFRList)
            .then(r => {
                //console.log(r)
                messageApi.success('兑换成功！！请出票！');
                setIsModalOpen(true)
                setIsError(false);
                setVerifiedNFR([NFRList])
            })
            .catch(e =>{
                console.log(e)
            })
            .finally(() => {

            })
    }

    const onFinish = (values) => {
        // //load and reset verifiedNFR
        setLoading(true)
        setVerifiedNFR([])

        let NFRList={'$and': [{"Activity": activityCat}]}
        let OrFind = {'$or':[]}
        let NFRKeyList = []
        let NFRNumberList = []
        _.forEach(values,(value, key) => {
            if(key.includes("nfr")){
                NFRKeyList.push(value)
            }
            if(key.includes("number")){
                NFRNumberList.push(value)
            }
        })

        for (let i = 0; i < NFRKeyList.length && i < NFRNumberList.length; i++) {
            OrFind.$or.push({"$and":[{"type": NFRKeyList[i]}, {"number": NFRNumberList[i]}]})
        }
        //combine or filter and activity
        NFRList.$and.push({"NFR": {"$elemMatch": OrFind}})

        //console.log(JSON.stringify(NFRList))
         collection.find(NFRList)
            .then(r => {
                if(r.length === 0) {
                    insertNewNFROrder(values)
                } else {
                    //console.log(r)
                    setVerifiedNFR(r)
                    setIsModalOpen(true)
                    setIsError(true)
                }
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setLoading(false)
            })
    };
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const onLogOut = () => {
        setLoading(true)
        realmApp.currentUser.logOut()
            .then(r => {
                store.dispatch(authenticate(false));
            })
            .finally(() =>{
                setLoading(false)
            });
    }
    const setNumberOfNFRs = () => {
        let form = [];
        for (let i = 1; i <= activityCat; i++) {
            form.push (
                <Form.Item key={i} label="数字藏品" style={{ marginBottom: 0 }}>
                    <Form.Item
                        name={i + '_nfr'}
                        rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}

                    >
                        <Select placeholder="请选择" >
                            <Option value="酷酷燕小侠">酷酷燕小侠</Option>
                            <Option value="躺赢燕小侠">躺赢燕小侠</Option>
                            <Option value="帅气燕小侠">帅气燕小侠</Option>
                            <Option value="有爱燕小侠">有爱燕小侠</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={i + '_number'}
                        rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                    >
                        <Input placeholder="请输入数藏编号" />
                    </Form.Item>
                </Form.Item>
            )
        }
        return form
    }

    const buildModal = () => {
        let list = []
        _.forEach(verifiedNFR, (nfr)=> {
           // console.log(nfr)
            let nfrInfos = []
            _.forEach(nfr.NFR, (nfrInfo) => {
              //  console.log(nfrInfo)
                nfrInfos.push(<>
                <p>种类：{nfrInfo.type}</p>
                 <p>号码：{nfrInfo.number}</p>
                </>)
            })
            list.push(<>
                <p>活动： {nfr.Activity}</p>
                <p>{nfrInfos}</p>
                <p>兑换日期： {nfr.Date}</p>
                <p>联系方式： {nfr.Contact_number}</p>
                <p>----------------------------------</p>
            </>)
        })
        return list
    }
    return (
        <>
            <Layout
                style={{
                    minHeight: '100vh',
                }}
            >
                {isLoading ? <Spin /> :<>
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                    <div
                        style={{
                            height: 32,
                            margin: 16,
                            background: 'rgba(255, 255, 255, 0.2)',
                        }}
                    />
                    <Menu theme="dark" defaultSelectedKeys={[activityCat.toString()]} mode="inline" items={items} onClick={onMenuClick} />
                </Sider>
                <Layout className="site-layout">
                    <Header
                        style={{
                            padding: 0,
                            background: colorBgContainer,
                        }}
                    />
                    <Content
                        style={{
                            margin: '0 16px',
                        }}
                    >
                        {contextHolder}
                        <div
                            style={{
                                padding: 24,
                                minHeight: 360,
                                background: colorBgContainer,
                            }}
                        >
                            <Form
                                name="basic"
                                form={form}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                style={{ maxWidth: 600 }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                {setNumberOfNFRs()}
                                <Form.Item key="phone_number" name="phone_number" label="联系电话" style={{ marginBottom: 10 }} rules={[{ required: true }]}>
                                    <Input placeholder="请输入联系电话号码" />
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit" disabled={isLoading}>
                                        提交
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <Button type="primary" onClick={onLogOut} disabled={isLoading}>登出</Button>
                        <Space></Space>
                        <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText="确认" cancelText="取消">
                            <Result
                                status={isError ? "error" : "success"}
                                title={isError ? "错误" : "成功"}
                                subTitle={isError ?  "以下盲盒已被兑换！" : "以下盲盒兑换成功!"}
                            >
                            {verifiedNFR.length > 0 && buildModal()}
                            </Result>
                        </Modal>
                    </Content>
                </Layout></>}
            </Layout></>
    )
}
export default Dashboard;
