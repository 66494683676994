import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthed: false,
    },
    reducers: {
        authenticate: (state, action) => {
            state.isAuthed = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { authenticate } = authSlice.actions

export default authSlice.reducer
