import * as Realm from "realm-web";
export const realmApp = new Realm.App({ id: "application-0-cfcvi" });

const {
    BSON: { ObjectId },
} = Realm;

export let collection

export async function loginEmailPassword(email, password) {
    // Create an email/password credential
    const credentials = Realm.Credentials.emailPassword(email, password);
    // Authenticate the user
    const user = await realmApp.logIn(credentials);
    // `App.currentUser` updates to match the logged in user
    console.assert(user.id === realmApp.currentUser.id);
    return user;
}

const mongo = realmApp.currentUser ? realmApp.currentUser.mongoClient("mongodb-atlas") : null;
collection = mongo ? mongo.db("NFR").collection("NFRVerification"): null;

export function connectToMongoDB(){
    const mongo = realmApp.currentUser.mongoClient("mongodb-atlas");
    collection = mongo.db("NFR").collection("NFRVerification");
}


export default realmApp;
