import { useSelector } from 'react-redux'
import './App.css';

import {Layout} from 'antd';
import Login from "./Login";
import Dashboard from "./Dashboard";

function App() {
    const isAuthed = useSelector((state) => state.isAuthed)

  return (
    <div className="App">
      <Layout className="layout">
          {isAuthed ? <Dashboard/> : <Login/>}
      </Layout>
    </div>
  );
}

export default App;
